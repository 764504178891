export const environment = {
  production: false,
  debug: true,
  apiUrl: 'https://axegazwebapitest.azurewebsites.net/',
 // apiUrl: 'https://localhost:44334/',
  adalConfig: {
    tenant: '8fabe025-b1db-4f0c-82ce-2f4a3ce2b985',
    clientId: 'd17095ef-2f49-4a03-ab0a-61f5384b489b',
    cacheLocation: 'localStorage',
    redirectUri: 'https://axecardwebapptest.z28.web.core.windows.net/' // 'https://axecardwebapptest.z28.web.core.windows.net/' // 'http://localhost:4200'
    //redirectUri: 'http://localhost:4200' // 'https://axecardwebapptest.z28.web.core.windows.net/' // 'http://localhost:4200'
  }
};
